// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Cfcustomnotetemplatescreator2 from "../../blocks/cfcustomnotetemplatescreator2/src/Cfcustomnotetemplatescreator2";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfonfidointegration2 from "../../blocks/cfonfidointegration2/src/Cfonfidointegration2";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Cfbrandedinterfacedocuments2 from "../../blocks/cfbrandedinterfacedocuments2/src/Cfbrandedinterfacedocuments2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Cfcustomroleandpermissioncreator2 from "../../blocks/cfcustomroleandpermissioncreator2/src/Cfcustomroleandpermissioncreator2";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfattendancetracking2 from "../../blocks/cfattendancetracking2/src/Cfattendancetracking2";
import Polling from "../../blocks/polling/src/Polling";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import Videos from "../../blocks/videos/src/Videos";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfringfencetherapists2 from "../../blocks/cfringfencetherapists2/src/Cfringfencetherapists2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Cfcloudflaredomainmapping2 from "../../blocks/cfcloudflaredomainmapping2/src/Cfcloudflaredomainmapping2";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import Cfdirectbilling2 from "../../blocks/cfdirectbilling2/src/Cfdirectbilling2";
import Cfactivityconsole2 from "../../blocks/cfactivityconsole2/src/Cfactivityconsole2";
import Cfmicrosoftdefenderforcloud5 from "../../blocks/cfmicrosoftdefenderforcloud5/src/Cfmicrosoftdefenderforcloud5";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import DailyScheduleNotifications from "../../blocks/dailyschedulenotifications/src/DailyScheduleNotifications";
import Cfmasksensitivefields2 from "../../blocks/cfmasksensitivefields2/src/Cfmasksensitivefields2";
import Cfringfencepatients2 from "../../blocks/cfringfencepatients2/src/Cfringfencepatients2";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Cfautomateddataencryption2 from "../../blocks/cfautomateddataencryption2/src/Cfautomateddataencryption2";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import CreateComment from "../../blocks/comments/src/CreateComment";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Cfbillingcodeoverridestacking2 from "../../blocks/cfbillingcodeoverridestacking2/src/Cfbillingcodeoverridestacking2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Cfdisablescreenshots4 from "../../blocks/cfdisablescreenshots4/src/Cfdisablescreenshots4";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Cfchangehealthcareadonnisapi from "../../blocks/cfchangehealthcareadonnisapi/src/Cfchangehealthcareadonnisapi";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cfonboardingjumpin2 from "../../blocks/cfonboardingjumpin2/src/Cfonboardingjumpin2";
import Cfbulkbillingsuperinvoices2 from "../../blocks/cfbulkbillingsuperinvoices2/src/Cfbulkbillingsuperinvoices2";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Cfaccesslevelmatrix2 from "../../blocks/cfaccesslevelmatrix2/src/Cfaccesslevelmatrix2";
import Cfbrandedsingupportal2 from "../../blocks/cfbrandedsingupportal2/src/Cfbrandedsingupportal2";
import Cfcustomonboardingflowcreator2 from "../../blocks/cfcustomonboardingflowcreator2/src/Cfcustomonboardingflowcreator2";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import Cfwhitelabelurl2 from "../../blocks/cfwhitelabelurl2/src/Cfwhitelabelurl2";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfcallqueueing2 from "../../blocks/cfcallqueueing2/src/Cfcallqueueing2";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Cfcoursemodules from "../../blocks/cfcoursemodules/src/Cfcoursemodules";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import Maps from "../../blocks/maps/src/Maps";
import Cfclickupintegration2 from "../../blocks/cfclickupintegration2/src/Cfclickupintegration2";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfnonstandardinfrasetup2 from "../../blocks/cfnonstandardinfrasetup2/src/Cfnonstandardinfrasetup2";

import VariantRegistration from "../../blocks/email-account-registration/src/VariantRegistration.web";
import AdminEmailLogin from "../../blocks/email-account-login/src/AdminEmailAccountLoginBlock.web"


const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Cfcustomnotetemplatescreator2:{
 component:Cfcustomnotetemplatescreator2,
path:"/Cfcustomnotetemplatescreator2"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfonfidointegration2:{
 component:Cfonfidointegration2,
path:"/Cfonfidointegration2"},
Share:{
 component:Share,
path:"/Share"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Cfbrandedinterfacedocuments2:{
 component:Cfbrandedinterfacedocuments2,
path:"/Cfbrandedinterfacedocuments2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Cfcustomroleandpermissioncreator2:{
 component:Cfcustomroleandpermissioncreator2,
path:"/Cfcustomroleandpermissioncreator2"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfattendancetracking2:{
 component:Cfattendancetracking2,
path:"/Cfattendancetracking2"},
Polling:{
 component:Polling,
path:"/Polling"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
Videos:{
 component:Videos,
path:"/Videos"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Cfringfencetherapists2:{
 component:Cfringfencetherapists2,
path:"/Cfringfencetherapists2"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Cfcloudflaredomainmapping2:{
 component:Cfcloudflaredomainmapping2,
path:"/Cfcloudflaredomainmapping2"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
Cfdirectbilling2:{
 component:Cfdirectbilling2,
path:"/Cfdirectbilling2"},
Cfactivityconsole2:{
 component:Cfactivityconsole2,
path:"/Cfactivityconsole2"},
Cfmicrosoftdefenderforcloud5:{
 component:Cfmicrosoftdefenderforcloud5,
path:"/Cfmicrosoftdefenderforcloud5"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
DailyScheduleNotifications:{
 component:DailyScheduleNotifications,
path:"/DailyScheduleNotifications"},
Cfmasksensitivefields2:{
 component:Cfmasksensitivefields2,
path:"/Cfmasksensitivefields2"},
Cfringfencepatients2:{
 component:Cfringfencepatients2,
path:"/Cfringfencepatients2"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Cfautomateddataencryption2:{
 component:Cfautomateddataencryption2,
path:"/Cfautomateddataencryption2"},
DesktopNotifications:{
 component:DesktopNotifications,
path:"/DesktopNotifications"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Cfbillingcodeoverridestacking2:{
 component:Cfbillingcodeoverridestacking2,
path:"/Cfbillingcodeoverridestacking2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Cfdisablescreenshots4:{
 component:Cfdisablescreenshots4,
path:"/Cfdisablescreenshots4"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Cfchangehealthcareadonnisapi:{
 component:Cfchangehealthcareadonnisapi,
path:"/Cfchangehealthcareadonnisapi"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cfonboardingjumpin2:{
 component:Cfonboardingjumpin2,
path:"/Cfonboardingjumpin2"},
Cfbulkbillingsuperinvoices2:{
 component:Cfbulkbillingsuperinvoices2,
path:"/Cfbulkbillingsuperinvoices2"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Cfaccesslevelmatrix2:{
 component:Cfaccesslevelmatrix2,
path:"/Cfaccesslevelmatrix2"},
Cfbrandedsingupportal2:{
 component:Cfbrandedsingupportal2,
path:"/Cfbrandedsingupportal2"},
Cfcustomonboardingflowcreator2:{
 component:Cfcustomonboardingflowcreator2,
path:"/Cfcustomonboardingflowcreator2"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Cfwhitelabelurl2:{
 component:Cfwhitelabelurl2,
path:"/Cfwhitelabelurl2"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfcallqueueing2:{
 component:Cfcallqueueing2,
path:"/Cfcallqueueing2"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Cfcoursemodules:{
 component:Cfcoursemodules,
path:"/Cfcoursemodules"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
Maps:{
 component:Maps,
path:"/Maps"},
Cfclickupintegration2:{
 component:Cfclickupintegration2,
path:"/Cfclickupintegration2"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfnonstandardinfrasetup2:{
 component:Cfnonstandardinfrasetup2,
path:"/Cfnonstandardinfrasetup2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  VariantRegistration: {
    component: VariantRegistration,
    path: '/variantRegistration'
  },
  AdminEmailLogin:{
    component:AdminEmailLogin,
    path:"/AdminLogin"},
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;