import React from "react";
import {
    Box,
    Button,
    Container,
    Typography,
    // Customizable Area Start
    Checkbox,
    TextField,
    Stepper,
    StepLabel,
    Step,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// Customizable Area End

import VariantRegistrationController, {
    Props,
} from "./VariantRegistrationController";

export default class VariantRegistartionScreen1 extends VariantRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
                <Container maxWidth={"sm"} id="var1">
                    <Stepper activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.steps.map((label: any) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={varWebStyle.mainWrapper}>
                        <Box sx={varWebStyle.firstBoxBlock3}>
                            {this.state.headingIsEditable ? (
                                <div style={varWebStyle.editPart}>
                                    <TextField
                                        InputProps={{ disableUnderline: true }}
                                        style={varWebStyle.nameInput}
                                        value={this.state.headingText}
                                        className="headingText"
                                        onChange={(e: any) => this.handleChangeheader(e.target.value)}
                                    />
                                    <SaveIcon className="saveIcon" onClick={() => this.setState({ headingIsEditable: false })} />
                                </div>
                            ) : (
                                <div style={varWebStyle.editPart}>
                                    <Typography style={varWebStyle.headingBlock3} component="div">
                                        {this.state.headingText}
                                    </Typography>
                                    <EditIcon className="editIcon" onClick={() => this.setState({ headingIsEditable: true })} />
                                </div>
                            )}

                            <Box sx={varWebStyle.secoundBoxBlock3}>
                                <Box sx={varWebStyle.miniBoxBlock3} >
                                    {this.state.subheadingIsEditable ? (
                                        <div style={varWebStyle.editPart}>
                                            <TextField
                                                InputProps={{ disableUnderline: true }}
                                                style={varWebStyle.nameInput}
                                                id='firstName'
                                                className="subHeadingText"
                                                value={this.state.subHeadingText}
                                                onChange={(e: any) => this.handleChangeSubheader(e.target.value)}
                                            />
                                            <SaveIcon id="saveScreen1" className="saveIcon1" onClick={() => this.setState({ subheadingIsEditable: false })} />
                                        </div>
                                    ) : (
                                        <div style={varWebStyle.editPart}>
                                            <Typography style={varWebStyle.subHeadingBlock3} component="div">
                                                {this.state.subHeadingText}
                                            </Typography>
                                            <EditIcon id="editScreen1" className="editIcon1" onClick={() => this.setState({ subheadingIsEditable: true })} />
                                        </div>
                                    )}
                                </Box>
                                <Box sx={varWebStyle.thirdBoxBlock3}>
                                    <Box sx={varWebStyle.lableName}>
                                        <Typography style={varWebStyle.name1}>First Name</Typography>
                                        <TextField
                                            InputProps={{ disableUnderline: true }}
                                            style={varWebStyle.nameInput}
                                            placeholder={"Enter your First Name"}
                                            id='firstName'
                                            value={this.state.firstName}
                                        />
                                    </Box>
                                    <Box sx={{ m: 2 }} />
                                    <Box sx={varWebStyle.lableName}>
                                        <Typography style={varWebStyle.name1}>Last Name</Typography>
                                        <TextField
                                            style={varWebStyle.nameInput}
                                            InputProps={{ disableUnderline: true }}
                                            data-test-id="txtInputLastName"
                                            placeholder={"Enter your Last Name"}
                                            id='lastName'
                                            value={this.state.lastName}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={varWebStyle.Mobilelable}>
                                    <Typography style={varWebStyle.name1}>Mobile Number</Typography>
                                    <TextField
                                        style={varWebStyle.nameInput}
                                        InputProps={{ disableUnderline: true }}
                                        placeholder={"+1(000) 000-0000"}
                                        id='no'
                                        value={this.state.phone}
                                    />
                                </Box>
                                <Box sx={varWebStyle.checkedTheory} style={{ marginTop: "-30px" }}>
                                    <Checkbox />
                                    I don't have a mobile - add a landline
                                </Box>
                                <Box sx={varWebStyle.Mobilelable} style={{ marginTop: "-15px" }}>
                                    <Typography style={varWebStyle.name1}>Email</Typography>
                                    <TextField
                                        style={varWebStyle.nameInput}
                                        InputProps={{ disableUnderline: true }}
                                        id='email'
                                        name="email"
                                        placeholder="Enter your email id"
                                        value={this.state.email}
                                    />
                                </Box>
                                <Box style={{ display: "flex", }}>
                                    <Box style={{ marginTop: "-1%" }}>
                                        <Checkbox className="checkBox" style={varWebStyle.checkBoxes} onClick={this.handleCheckBoxClick} />
                                    </Box>
                                    <Typography style={varWebStyle.normalText}>I agree with the</Typography>
                                    <Typography style={varWebStyle.privacyPolicy}> Terms of Use </Typography>
                                    <Typography style={varWebStyle.normalTextPad}> and </Typography>
                                    <Typography style={varWebStyle.privacyPolicy}>Privacy Policy</Typography>
                                </Box>
                                <Box style={{ width: "74%" }}>
                                    <Button
                                        style={(this.state.term_and_condition) ? varWebStyle.ContinueBtn1 : varWebStyle.ContinueBtn}
                                        variant="contained"
                                        fullWidth
                                        {...this.btnSignUpProps}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                                <Box style={{ width: "74%" }}>
                                    <Button
                                        style={varWebStyle.SignupBtn}
                                        variant="contained"
                                        fullWidth
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="24"
                                            viewBox="0 0 25 24"
                                            fill="none"
                                        >
                                            <rect
                                                width="24"
                                                height="24"
                                                transform="translate(0.5)"
                                                fill="white"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M23.54 12.2605C23.54 11.445 23.4668 10.6609 23.3309 9.9082H12.5V14.3566H18.6891C18.4225 15.7941 17.6123 17.0121 16.3943 17.8275V20.713H20.1109C22.2855 18.7109 23.54 15.7627 23.54 12.2605Z"
                                                fill="#4285F4"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M12.4995 23.5005C15.6045 23.5005 18.2077 22.4708 20.1104 20.7144L16.3938 17.829C15.364 18.519 14.0467 18.9267 12.4995 18.9267C9.50425 18.9267 6.96902 16.9037 6.0647 14.1855H2.22266V17.1651C4.11493 20.9235 8.00402 23.5005 12.4995 23.5005Z"
                                                fill="#34A853"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M6.06523 14.1855C5.83523 13.4955 5.70455 12.7584 5.70455 12.0005C5.70455 11.2425 5.83523 10.5055 6.06523 9.81548V6.83594H2.22318C1.44432 8.38844 1 10.1448 1 12.0005C1 13.8562 1.44432 15.6125 2.22318 17.165L6.06523 14.1855Z"
                                                fill="#FBBC05"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M12.4995 5.07386C14.1879 5.07386 15.7038 5.65409 16.8956 6.79364L20.194 3.49523C18.2024 1.63955 15.5992 0.5 12.4995 0.5C8.00402 0.5 4.11493 3.07705 2.22266 6.83545L6.0647 9.815C6.96902 7.09682 9.50425 5.07386 12.4995 5.07386Z"
                                                fill="#EA4335"
                                            />
                                        </svg>
                                        Sign Up with Google
                                    </Button>
                                </Box>
                                <Box>
                                    <Typography style={varWebStyle.AlreadyLogin}>Already have an account? Log in</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            // Customizable Area End

        )
    }
}

// Customizable Area Start
const varWebStyle = {
    editPart: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px"
    } as any,
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px",
        background: "#fff",
    },
    firstBoxBlock3: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins, sans-serif'
    },
    headingBlock3: {
        color: "#2C2524",
        fontFamily: 'Raleway , sans-serif',
        fontSize: "33px",
        fontStyle: "normal",
        lineHeight: "130%",
        fontWeight: 600,
        letterSpacing: "0.5px",
        display: "flex",
        textAlign: "center",
    } as any,
    secoundBoxBlock3: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins,sans-serif'
    },
    subHeadingBlock3: {
        color: '#2C2524',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',

    },
    thirdBoxBlock3: {
        display: "flex",
        width: "74%"
    },
    lableName: {
        display: "flex",
        width: "76%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px"
    },
    name1: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%"
    },
    nameInput: {
        borderRadius: "24px",
        border: "1px solid #E6C79C",
        background: "#FFF",
        display: "flex",
        padding: "4px 14px",
        alignItems: "left",
        gap: "8px",
        alignSelf: "stretch"
    },
    checkedTheory: {
        width: "76%",
        color: "#2C2524",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%"
    },
    miniBoxBlock3: {
        width: "80%",
        textAlign: 'center'
    },
    privacyPolicy: {
        color: '#1A4A42',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontStyle: 'italic',
        fontWeight: 400,
        lineHeight: '150%',
        textDecorationLine: 'underline',
    },
    normalText: {
        color: '#2C2524',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    normalTextPad: {
        color: '#2C2524',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        paddingLeft: '7px',
        paddingRight: '7px'
    },
    checkBoxes: {
        color: "#1A4A42",
        border: "5px"
    },
    ContinueBtn: {
        display: "flex",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        background: "#D6D3D1",
        border: "#D6D3D1",
        fontWeight: 500
    },
    ContinueBtn1: {
        display: "flex",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        background: "#E46760",
        border: "#E46760",
        color: "#FFFAF6",
        fontWeight: 500
    },
    Mobilelable: {
        display: "flex",
        width: "74%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
    },
    SignupBtn: {
        display: "flex",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        border: "2px solid var(--neutrals-warm-gray-300, #D6D3D1)",
        background: "#FFF",
    },
    AlreadyLogin: {
        color: "#E46760",
        fontFamily: "Raleway",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal%",
        letterSpacing: "1px",
        textDecorationLine: "underline"
    }
};
// Customizable Area End
