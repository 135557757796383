import React from "react";
import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Checkbox,
    TextField,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// Customizable Area End

import VariantRegistrationController, {
    Props,
} from "./VariantRegistrationController";

export default class VariantRegistartionScreen8 extends VariantRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
    // Customizable Area Start

                <Container maxWidth={"sm"} id="var8">
                    <Box sx={webStyle8.mainWrapperScreen8}>
                        <Box sx={webStyle8.firstBoxBlock3Screen8}>
                        {this.state.headingIsEditable ? (
                                <div style={webStyle8.editPartScreen8}>
                                    <TextField
                                        InputProps={{ disableUnderline: true }}
                                        style={webStyle8.nameInputScreen8}
                                        value={this.state.headingText}
                                        className="headingText"
                                        onChange={(e: any) => this.handleChangeheader(e.target.value)}
                                    />
                                    <SaveIcon className="saveIcon" onClick={() => this.setState({ headingIsEditable: false })} />
                                </div>
                            ) : (
                                <div style={webStyle8.editPartScreen8}>
                                    <Typography style={webStyle8.headingBlock3Screen8} component="div">
                                        {this.state.headingText}
                                    </Typography>
                                    <EditIcon className="editIcon" onClick={() => this.setState({ headingIsEditable: true })} />
                                </div>
                            )}
                            <Box sx={webStyle8.secoundBoxBlock3Screen8}>
                                <Box sx={webStyle8.miniBoxBlock3Screen8} >
                                {this.state.subheadingIsEditable ? (
                                        <div style={webStyle8.editPartScreen8}>
                                            <TextField
                                                InputProps={{ disableUnderline: true }}
                                                style={webStyle8.nameInputScreen8}
                                                id='firstName'
                                                className="subHeadingText"
                                                value={this.state.subHeadingText}
                                                onChange={(e: any) => this.handleChangeSubheader(e.target.value)}
                                            />
                                            <SaveIcon className="saveIcon1" onClick={() => this.setState({ subheadingIsEditable: false })} />
                                        </div>
                                    ) : (
                                        <div style={webStyle8.editPartScreen8}>
                                            <Typography style={webStyle8.subHeadingBlock3Screen8} component="div">
                                                {this.state.subHeadingText}
                                            </Typography>
                                            <EditIcon className="editIcon1" onClick={() => this.setState({ subheadingIsEditable: true })} />
                                        </div>
                                    )}
                                </Box>
                                <Box sx={webStyle8.MobilelableScreen8} style={{ marginTop: "-15px" }}>
                                    <Typography style={webStyle8.name1Screen8}>Email</Typography>
                                    <TextField
                                        style={webStyle8.nameInputScreen8}
                                        InputProps={{ disableUnderline: true }}
                                        id='email'
                                        name="email"
                                        value={this.state.email}
                                        className="emailField"
                                        onChange={(e) => { this.txtInputEmailWebPrpos.onChange(e.target.value) }}
                                    />
                                </Box>
                                <Box style={{ display: "flex", }}>
                                    <Box style={{ marginTop: "-1%" }}>
                                        <Checkbox className="checkBox" style={webStyle8.checkBoxes8} onClick={() => { this.setState({ term_and_condition: !this.state.term_and_condition }) }} />
                                    </Box>
                                    <Typography style={webStyle8.normalTextScreen8}>I agree with the</Typography>
                                    <Typography style={webStyle8.privacyPolicyScreen8}> Terms of Use </Typography>
                                    <Typography style={webStyle8.normalTextPadScreen8}> and </Typography>
                                    <Typography style={webStyle8.privacyPolicyScreen8}>Privacy Policy</Typography>
                                </Box>
                                <Box style={{ width: "74%", marginTop: "10%" }}>
                                    <Button
                                        style={(webStyle8.nxtBtnScreen8)}
                                        variant="contained"
                                        fullWidth
                                        {...this.btnSignUpProps}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
    // Customizable Area Start

        )
    }
}

// Customizable Area Start
const webStyle8 = {
    mainWrapperScreen8: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px",
        background: "#fff",
    },
    firstBoxBlock3Screen8: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins, sans-serif'
    },
    headingBlock3Screen8: {
        color: "#2C2524",
        fontFamily: 'Raleway , sans-serif',
        fontSize: "33px",
        fontStyle: "normal",
        lineHeight: "130%",
        fontWeight: 600,
        letterSpacing: "0.5px",
        display:"flex",
        textAlign:"center",
    } as any,
    secoundBoxBlock3Screen8: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins,sans-serif'
    },
    subHeadingBlock3Screen8: {
        color: '#2C2524',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    name1Screen8: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%"
    },
    nameInputScreen8: {
        borderRadius: "24px",
        border: "1px solid #E6C79C",
        background: "#FFF",
        display: "flex",
        padding: "4px 14px",
        alignItems: "left",
        gap: "8px",
        alignSelf: "stretch"
    },
    miniBoxBlock3Screen8: {
        width: "80%",
        textAlign: 'center'
    },
    privacyPolicyScreen8: {
        color: '#1A4A42',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontStyle: 'italic',
        fontWeight: 400,
        lineHeight: '150%',
        textDecorationLine: 'underline',
    },
    normalTextScreen8: {
        color: '#2C2524',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    normalTextPadScreen8: {
        color: '#2C2524',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        paddingLeft: '7px',
        paddingRight: '7px'
    },
    checkBoxes8: {
        color: "#1A4A42",
        border: "5px"
    },
    nxtBtnScreen8: {
        display: "flex",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        background: "#E46760",
        border: "#E46760",
        color: "#FFFAF6",
        fontWeight: 500
    },
    MobilelableScreen8: {
        display: "flex",
        width: "74%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
    },
    editPartScreen8: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px"
    } as any,
};
// Customizable Area End
