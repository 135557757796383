import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  steps:any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  // emailValid:boolean;
  emailValid: boolean;
  otp: Array<string>;
  isOtpVerified: boolean;
  isPopUp: boolean;
  term_and_condition: boolean;
  pin: number;
  activeStep: number;
  selectedScreens: string;
  headingIsEditable: boolean;
  subheadingIsEditable: boolean;
  headingText: string;
  subHeadingText: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VariantRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveScreenApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      otp: ['', '', '', ''],
      steps:[
        "Learn about you",
        "Verify insurance",
        "Select provider",
      ],
      isOtpVerified: false,
      emailValid: false,
      isPopUp: false,
      term_and_condition: false,
      pin: 0,
      activeStep: 0,
      selectedScreens: "1",
      headingIsEditable: false,
      subheadingIsEditable: false,
      headingText: 'Your Wellness Journey Starts Here',
      subHeadingText: 'We need a few details to schedule your session and verify your insurance coverage.',
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(responseJson != null){
        if(this.saveScreenApiCallId === apiRequestCallId){
           alert("Screen saved successfully")
        }
      }
    }
    // Customizable Area End
  }
  saveScreen = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveScreenApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveScreenEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    let formData = new FormData();
    formData.append('custom[screen_no]', this.state.selectedScreens);
    formData.append('custom[heading]', this.state.headingText);
    formData.append('custom[sub_heading]', this.state.subHeadingText);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  txtInputEmailWebPrpos = {
    onChange: (text: string) => {
      this.setState({ email: text });
    },
  };
  btnSignUpProps = {
    onClick: () => {
    }
  };

  // web events
  handleChangeScreen = (event: any) => {
    this.setState({ selectedScreens: event.target.value });
  };
  handleScreenPrev = () => {
    let temp = Number(this.state.selectedScreens) - 1
    this.setState({ selectedScreens: "" + temp })
  }
  handleChangeheader=(value:any)=>{
    this.setState({ headingText: value })
  }
  handleChangeSubheader=(value:any)=>{
    this.setState({ subHeadingText: value })
  }
  handleCheckBoxClick=()=>{
    this.setState({ term_and_condition: !this.state.term_and_condition })
  }
  handleScreenNext = () => {
    let temp = Number(this.state.selectedScreens) + 1
    this.setState({ selectedScreens: "" + temp })
  }
  // Customizable Area Start
  // Customizable Area End
}
