import React from "react";
import {
    Box,
    Button,
    // Customizable Area Start
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import RegistrationFooter from './RegistrationFooter.web'
import VariantRegistartionScreen1 from "./VariantRegistrationScreen1.web";
import VariantRegistartionScreen2 from "./VariantRegistrationScreen2.web";
import VariantRegistartionScreen3 from "./VariantRegistrationScreen3.web";
import VariantRegistartionScreen4 from "./VariantRegistrationScreen4.web";
import VariantRegistartionScreen5 from "./VariantRegistrationScreen5.web";
import VariantRegistartionScreen6 from "./VariantRegistrationScreen6.web";
import VariantRegistartionScreen7 from "./VariantRegistrationScreen7.web";
import VariantRegistartionScreen8 from "./VariantRegistrationScreen8.web";
import VariantRegistartionScreen9 from "./VariantRegistrationScreen9.web";

// Customizable Area End
import VariantRegistrationController, {
    Props,
} from "./VariantRegistrationController";

export default class VariantRegistartion extends VariantRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderContent() {
        const { selectedScreens } = this.state;
        switch (selectedScreens) {
            case '1':
                return <VariantRegistartionScreen1 navigation={undefined} id={""} />;
            case '2':
                return <VariantRegistartionScreen2 navigation={undefined} id={""} />;
            case '3':
                return <VariantRegistartionScreen3 navigation={undefined} id={""} />;
            case '4':
                return <VariantRegistartionScreen4 navigation={undefined} id={""} />;
            case '5':
                return <VariantRegistartionScreen5 navigation={undefined} id={""} />;
            case '6':
                return <VariantRegistartionScreen6 navigation={undefined} id={""} />;
            case '7':
                return <VariantRegistartionScreen7 navigation={undefined} id={""} />;
            case '8':
                return <VariantRegistartionScreen8 navigation={undefined} id={""} />;
            case '9':
                return <VariantRegistartionScreen9 navigation={undefined} id={""} />;
            default:
                return <div>Default Content</div>;
        }
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box sx={webStyle.mainDiv}>
                <FormControl component="fieldset" style={webStyle.scrrenContainer}>
                    <FormLabel component="legend">Select Screens</FormLabel>
                    <RadioGroup row aria-label="position" className="radioOption" value={this.state.selectedScreens} name="position" defaultValue="1" onChange={this.handleChangeScreen}>
                        {[...Array(9).keys()].map((value) => (
                            <FormControlLabel
                                key={value}
                                value={(value + 1).toString()}
                                control={<Radio color="primary" />}
                                label={`Screen ${value + 1}`}
                                labelPlacement="end"
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <div style={webStyle.templateContainer}>
                    {this.renderContent()}
                    <RegistrationFooter navigation={undefined} id={""} />
                </div>
                <div style={{ display: "flex", alignItems: "ccenter", gap: "20px" }}>
                    <Button disabled={this.state.selectedScreens === "1"}
                        className="prevButton"
                        onClick={this.handleScreenPrev} >
                        Back
                    </Button>
                    <Button
                        className="nextButton"
                        variant="contained"
                        style={webStyle.nextButton}
                        disabled={this.state.selectedScreens === "9"}
                        onClick={this.handleScreenNext}
                        test-id="nextButton"
                    >
                        Next
                    </Button>
                    <Button
                        variant="contained"
                        style={webStyle.saveButton}
                        onClick={this.saveScreen}
                        className="saveBtn"
                    >
                        Save
                    </Button>
                </div>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainDiv: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "1%" },
    templateContainer: {
        border: "1px solid black", width: "80%", margin: "0 10% 2% 10%", padding: "2% 0 0 0 "
    },
    scrrenContainer: {
        margin: "2% 10%"
    },
    nextButton: {
        backgroundColor: 'blue',
        color: 'white',
    },
    saveButton: {
        backgroundColor: 'green',
        color: 'white',
    },
};
// Customizable Area End
