import React from "react";
import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    TextField,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
    overrides: {
        MuiStepIcon: {
            active: {
                color: 'black !important',
            },
        },
    },
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import AdminEmailAccountLoginController, {
    Props,
} from "./AdminEmailAccountLoginController";

export default class AdminEmailAccountLogin extends AdminEmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
           // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth={"sm"}>
                    <Box sx={webStyle.mainWrapper}>
                        <Box sx={webStyle.firstBoxBlock3}>
                            <Box sx={webStyle.secoundBoxBlock3}>
                                <Box sx={webStyle.Mobilelable}>
                                    <Typography style={webStyle.name1}>Email</Typography>
                                    <TextField
                                        style={webStyle.nameInput}
                                        InputProps={{ disableUnderline: true }}
                                        id='email'
                                        name="email"
                                        className="emailField"
                                        value={this.state.email}
                                        onChange={(e) => { this.txtInputEmailWebPrpos.onChange(e.target.value) }}
                                    />
                                </Box>

                                <Box style={{ width: "74%", marginTop: "20%" }}>
                                    <Button
                                        style={(webStyle.nxtBtn)}
                                        variant="contained"
                                        fullWidth
                                        className="loginBtn"
                                        onClick={this.handleLogin}
                                    >
                                        Login
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
          // Customizable Area End

        )
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        padding:"20%"
    },
    firstBoxBlock3: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins, sans-serif'
    },
    secoundBoxBlock3: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins,sans-serif'
    },
    name1: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%"
    },
    nameInput: {
        borderRadius: "24px",
        border: "1px solid #E6C79C",
        background: "#FFF",
        display: "flex",
        padding: "4px 14px",
        alignItems: "left",
        gap: "8px",
        alignSelf: "stretch"
    },
    nxtBtn: {
        display: "flex",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        background: "#E46760",
        border: "#E46760",
        color: "#FFFAF6",
        fontWeight: 500
    },
    Mobilelable: {
        display: "flex",
        width: "74%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
    },
};
// Customizable Area End
