import React from "react";
import {
    Box,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { img_call, img_warning } from "./assets";
const theme = createTheme({
    overrides: {
        MuiStepIcon: {
            active: {
                color: 'black !important',
            },
        },
    },
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import VariantRegistrationController, {
    Props,
} from "./VariantRegistrationController";

export default class FooterPage extends VariantRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.footer}></Box>
                <Box style={webStyle.footerBlock}>
                    <Box style={webStyle.footerBoxOne}>
                        <Typography style={{ marginLeft: "5%" }}>
                            <img
                                src={img_warning}
                                // style={styles.editicon}
                                data-test-id="editProfileSubmitBtn"
                            />
                        </Typography>
                        <Typography style={webStyle.Emergency}>If this is an emergency</Typography>
                        <Typography style={webStyle.call}>please call 911</Typography>
                    </Box>
                    <Box style={webStyle.footerBoxtwo}>
                        <Typography style={webStyle.help}>Need Help?</Typography>
                        <Box sx={webStyle.footerBoxThree}>
                            <Typography
                                style={webStyle.schedule}>Schedule a Call
                            </Typography>
                            <Typography>
                                <img
                                    src={img_call}
                                />
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyle = {
    footerBlock: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: '1% 0',
        gap: "20%"
    },
    footerBoxOne: {
        display: "flex",
        width: "50%",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    footerBoxtwo: {
        display: "flex",
        width: "50%",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    footerBoxThree: {
        display: "flex",
        height: "19px",
        padding: "10px 16px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "16px",
        border: "2px solid var(--Total-Life-Red-Coral-100, #E46760)",
        marginTop: "-1%",
        marginLeft: "1%"
    },
    Mobilelable: {
        display: "flex",
        width: "74%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
    },
    SignupBtn: {
        display: "flex",
        // width: "600px",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        border: "2px solid var(--neutrals-warm-gray-300, #D6D3D1)",
        background: "#FFF",
        // textTransform: 'none'
    },
    /* Total Life/CTA - Buttons */
    footer: {
        height: "0.2%",
        flexShrink: 0,
        background: "#D6D3D1",
        marginTop: "4%",
    },
    Emergency: {
        color: "#2C2524",
        fontFamily: 'Poppins, sans-serif',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        // float: 'right',
    },
    call: {
        color: "#D97706",
        fontFamily: 'Poppins, sans-serif',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
        textDecorationLine: "underline",
        marginLeft: "1%",
    },
    help: {
        color: "##2C2524",
        fontFamily: 'Poppins, sans-serif',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        // marginLeft: "50%",
    },
    schedule: {
        color: "#E46760",
        fontFeatureSettings: 'liga off',
        fontFamily: 'Raleway',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "1px",
        // textTransform: 'none',
        // marginLeft: "17px",
    },
    AlreadyLogin: {
        color: "#E46760",
        fontFamily: "Raleway",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal%",
        letterSpacing: "1px",
        textDecorationLine: "underline"
    }
};
// Customizable Area End
