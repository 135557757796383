import React from "react";
import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    TextField,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// Customizable Area End
import VariantRegistrationController, {
    Props,
} from "./VariantRegistrationController";

export default class VariantRegistartionScreen7 extends VariantRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Container maxWidth={"sm"} id="var7">
                <Box sx={webStyle7.mainWrapperScreen7}>
                    <Box sx={webStyle7.firstBoxBlock3Screen7}>
                        {this.state.headingIsEditable ? (
                            <div style={webStyle7.editPartScreen7}>
                                <TextField
                                    InputProps={{ disableUnderline: true }}
                                    style={webStyle7.nameInputScreen7}
                                    value={this.state.headingText}
                                    className="headingText"
                                    onChange={(e: any) => this.handleChangeheader(e.target.value)}
                                />
                                <SaveIcon className="saveIcon" onClick={() => this.setState({ headingIsEditable: false })} />
                            </div>
                        ) : (
                            <div style={webStyle7.editPartScreen7}>
                                <Typography style={webStyle7.headingBlock3Screen7} component="div">
                                    {this.state.headingText}
                                </Typography>
                                <EditIcon className="editIcon" onClick={() => this.setState({ headingIsEditable: true })} />
                            </div>
                        )}
                        <Box sx={webStyle7.secoundBoxBlock3Screen7}>
                            <Box sx={webStyle7.miniBoxBlock3Screen7} >
                                {this.state.subheadingIsEditable ? (
                                    <div style={webStyle7.editPartScreen7}>
                                        <TextField
                                            InputProps={{ disableUnderline: true }}
                                            style={webStyle7.nameInputScreen7}
                                            id='firstName'
                                            className="subHeadingText"
                                            value={this.state.subHeadingText}
                                            onChange={(e: any) => this.handleChangeSubheader(e.target.value)}
                                        />
                                        <SaveIcon className="saveIcon1" onClick={() => this.setState({ subheadingIsEditable: false })} />
                                    </div>
                                ) : (
                                    <div style={webStyle7.editPartScreen7}>
                                        <Typography style={webStyle7.subHeadingBlock3Screen7} component="div">
                                            {this.state.subHeadingText}
                                        </Typography>
                                        <EditIcon className="editIcon1" onClick={() => this.setState({ subheadingIsEditable: true })} />
                                    </div>
                                )}
                            </Box>
                            <Box sx={webStyle7.MobilelableScreen7} style={{ marginTop: "-15px" }}>
                                <Typography style={webStyle7.name1Screen7}>Email</Typography>
                                <TextField
                                    style={webStyle7.nameInputScreen7}
                                    InputProps={{ disableUnderline: true }}
                                    id='email'
                                    name="email"
                                    className="emailField"
                                    value={this.state.email}
                                    onChange={(e) => { this.txtInputEmailWebPrpos.onChange(e.target.value) }}
                                />
                            </Box>

                            <Box style={{ width: "74%", marginTop: "20%" }}>
                                <Button
                                    style={(webStyle7.nxtBtnScreen7)}
                                    variant="contained"
                                    fullWidth
                                    {...this.btnSignUpProps}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            // Customizable Area End

        )
    }
}

// Customizable Area Start
const webStyle7 = {
    mainWrapperScreen7: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px",
        background: "#fff",
    },
    firstBoxBlock3Screen7: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins, sans-serif'
    },
    headingBlock3Screen7: {
        color: "#2C2524",
        fontFamily: 'Raleway , sans-serif',
        fontSize: "33px",
        fontStyle: "normal",
        lineHeight: "130%",
        fontWeight: 600,
        letterSpacing: "0.5px",
        display: "flex",
        textAlign: "center",
    } as any,
    secoundBoxBlock3Screen7: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins,sans-serif'
    },
    subHeadingBlock3Screen7: {
        color: '#2C2524',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    name1Screen7: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%"
    },
    nameInputScreen7: {
        borderRadius: "24px",
        border: "1px solid #E6C79C",
        background: "#FFF",
        display: "flex",
        padding: "4px 14px",
        alignItems: "left",
        gap: "8px",
        alignSelf: "stretch"
    },
    miniBoxBlock3Screen7: {
        width: "80%",
        textAlign: 'center'
    },
    nxtBtnScreen7: {
        display: "flex",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        background: "#E46760",
        border: "#E46760",
        color: "#FFFAF6",
        fontWeight: 500
    },
    MobilelableScreen7: {
        display: "flex",
        width: "74%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
    },
    editPartScreen7: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px"
    } as any,
};
// Customizable Area End
