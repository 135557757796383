import React from "react";
import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Checkbox,
    TextField,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

// Customizable Area End
import VariantRegistrationController, {
    Props,
} from "./VariantRegistrationController";

export default class VariantRegistartionScreen9 extends VariantRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
    // Customizable Area Start

                <Container maxWidth={"sm"} id="var9">
                    <Box sx={webStyle9.mainWrapperScreen9}>
                        <Box sx={webStyle9.firstBoxBlock3Screen9}>
                        {this.state.headingIsEditable ? (
                                <div style={webStyle9.editPartScreen9}>
                                    <TextField
                                        InputProps={{ disableUnderline: true }}
                                        style={webStyle9.nameInputScreen9}
                                        value={this.state.headingText}
                                        className="headingText"
                                        onChange={(e: any) => this.handleChangeheader(e.target.value)}
                                    />
                                    <SaveIcon className="saveIcon" onClick={() => this.setState({ headingIsEditable: false })} />
                                </div>
                            ) : (
                                <div style={webStyle9.editPartScreen9}>
                                    <Typography style={webStyle9.headingBlock3Screen9} component="div">
                                        {this.state.headingText}
                                    </Typography>
                                    <EditIcon className="editIcon" onClick={() => this.setState({ headingIsEditable: true })} />
                                </div>
                            )}
                            <Box sx={webStyle9.secoundBoxBlock3Screen9}>
                                <Box sx={webStyle9.miniBoxBlock3Screen9} >
                                {this.state.subheadingIsEditable ? (
                                        <div style={webStyle9.editPartScreen9}>
                                            <TextField
                                                InputProps={{ disableUnderline: true }}
                                                style={webStyle9.nameInputScreen9}
                                                id='firstName'
                                                className="subHeadingText"
                                                value={this.state.subHeadingText}
                                                onChange={(e: any) => this.handleChangeSubheader(e.target.value)}
                                            />
                                            <SaveIcon className="saveIcon1" onClick={() => this.setState({ subheadingIsEditable: false })} />
                                        </div>
                                    ) : (
                                        <div style={webStyle9.editPartScreen9}>
                                            <Typography style={webStyle9.subHeadingBlock3Screen9} component="div">
                                                {this.state.subHeadingText}
                                            </Typography>
                                            <EditIcon className="editIcon1" onClick={() => this.setState({ subheadingIsEditable: true })} />
                                        </div>
                                    )}
                                </Box>
                                <Box sx={webStyle9.thirdBoxBlock3Screen9}>
                                    <Box sx={webStyle9.lableNameScreen9}>
                                        <Typography style={webStyle9.name1Screen9}>First Name</Typography>
                                        <TextField
                                            InputProps={{ disableUnderline: true }}
                                            style={webStyle9.nameInputScreen9}
                                            placeholder={"Enter your First Name"}
                                            id='firstName'
                                            value={this.state.firstName}
                                        />
                                    </Box>
                                    <Box sx={{ m: 2 }} />
                                    <Box sx={webStyle9.lableNameScreen9}>
                                        <Typography style={webStyle9.name1Screen9}>Last Name</Typography>
                                        <TextField
                                            style={webStyle9.nameInputScreen9}
                                            InputProps={{ disableUnderline: true }}
                                            data-test-id="txtInputLastName"
                                            placeholder={"Enter your Last Name"}
                                            id='lastName'
                                            value={this.state.lastName}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={webStyle9.MobilelableScreen9}>
                                    <Typography style={webStyle9.name1Screen9}>Mobile Number</Typography>
                                    <TextField
                                        style={webStyle9.nameInputScreen9}
                                        InputProps={{ disableUnderline: true }}
                                        placeholder={"+1(000) 000-0000"}
                                        id='no'
                                        value={this.state.phone}
                                    />
                                </Box>
                                <Box style={{ display: "flex", }}>
                                    <Box style={{ marginTop: "-1%" }}>
                                        <Checkbox className="checkBox" style={webStyle9.checkBoxesScreen9} onClick={() => { this.setState({ term_and_condition: !this.state.term_and_condition }) }} />
                                    </Box>
                                    <Typography style={webStyle9.normalTextScreen9}>I agree with the</Typography>
                                    <Typography style={webStyle9.privacyPolicyScreen9}> Terms of Use </Typography>
                                    <Typography style={webStyle9.normalTextPadScreen9}> and </Typography>
                                    <Typography style={webStyle9.privacyPolicyScreen9}>Privacy Policy</Typography>
                                </Box>
                                <Box style={{ width: "74%" }}>
                                    <Button
                                        style={(webStyle9.nxtBtnScreen9)}
                                        variant="contained"
                                        fullWidth
                                        {...this.btnSignUpProps}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
    // Customizable Area End

        )
    }
}

// Customizable Area Start
const webStyle9 = {
    mainWrapperScreen9: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "20px",
        background: "#fff",
    },
    firstBoxBlock3Screen9: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins, sans-serif'
    },
    headingBlock3Screen9: {
        color: "#2C2524",
        fontFamily: 'Raleway , sans-serif',
        fontSize: "33px",
        fontStyle: "normal",
        lineHeight: "130%",
        fontWeight: 600,
        letterSpacing: "0.5px",
        display:"flex",
        textAlign:"center",
    } as any,
    secoundBoxBlock3Screen9: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        width: "120%",
        fontFamily: 'Poppins,sans-serif'
    },
    subHeadingBlock3Screen9: {
        color: '#2C2524',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    thirdBoxBlock3Screen9: {
        display: "flex",
        width: "74%"
    },
    lableNameScreen9: {
        display: "flex",
        width: "76%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px"
    },
    name1Screen9: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%"
    },
    nameInputScreen9: {
        borderRadius: "24px",
        border: "1px solid #E6C79C",
        background: "#FFF",
        display: "flex",
        padding: "4px 14px",
        alignItems: "left",
        gap: "8px",
        alignSelf: "stretch"
    },
    miniBoxBlock3Screen9: {
        width: "80%",
        textAlign: 'center'
    },
    privacyPolicyScreen9: {
        color: '#1A4A42',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px',
        fontStyle: 'italic',
        fontWeight: 400,
        lineHeight: '150%',
        textDecorationLine: 'underline',
    },
    normalTextScreen9: {
        color: '#2C2524',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    normalTextPadScreen9: {
        color: '#2C2524',
        fontFamily: 'Raleway, sans-serif',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        paddingLeft: '7px',
        paddingRight: '7px'
    },
    checkBoxesScreen9: {
        color: "#1A4A42",
        border: "5px"
    },
    nxtBtnScreen9: {
        display: "flex",
        height: "45px",
        padding: "16px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderRadius: "24px",
        background: "#E46760",
        border: "#E46760",
        color: "#FFFAF6",
        fontWeight: 500
    },
    MobilelableScreen9: {
        display: "flex",
        width: "74%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
    },
    editPartScreen9: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px"
    } as any,
};
// Customizable Area End
